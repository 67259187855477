<template>
  <div>
    <div style="padding: 10px 50px">
      <h3 style="text-align: left; color: #696969">
        {{ $store.state.common.sScreenTitle }}
      </h3>
      <hr />
    </div>

    <div v-show="$store.state.common.sIsLoading">Loading...</div>
    <div v-show="!$store.state.common.sIsLoading">
      <div style="padding: 10px">
        <div style="width: 100%; text-align: right; margin-bottom: 10px">
          <button
            type="button"
            class="operate-button"
            style="
              width: 10%;
              line-height: 3;
              background-color: #ffffff;
              border-radius: 10px;
              border: solid 1px #696969;
              color: #696969;
            "
            @click="doSwitchInputArea(0)"
          >
            再表示
          </button>
          <button
            type="button"
            class="operate-button"
            style="
              width: 10%;
              line-height: 3;
              background-color: #ffffff;
              border-radius: 10px;
              border: solid 1px #696969;
              color: #696969;
            "
            @click="doSwitchInputArea(1)"
            :style="vUpdateMode == 1 ? nowUpdateMode : ''"
          >
            追加
          </button>
          <button
            type="button"
            class="operate-button"
            style="
              width: 10%;
              line-height: 3;
              background-color: #ffffff;
              border-radius: 10px;
              border: solid 1px #696969;
              color: #696969;
            "
            @click="doSwitchInputArea(2)"
            :style="vUpdateMode == 2 ? nowUpdateMode : ''"
          >
            変更
          </button>
          <button
            type="button"
            class="operate-button"
            style="
              width: 10%;
              line-height: 3;
              background-color: #ffffff;
              border-radius: 10px;
              border: solid 1px #696969;
              color: #696969;
            "
            @click="doSwitchInputArea(3)"
            :style="vUpdateMode == 3 ? nowUpdateMode : ''"
          >
            削除
          </button>
        </div>
      </div>

      <!-- データ一覧 -->
      <div style="padding: 10px">
        <div
          style="
            width: 100%;
            text-align: center;
            margin-bottom: 10px;
            height: 200px;
            overflow: scroll;
          "
        >
          <table class="sc-table" border="1">
            <thead>
              <th>行№</th>
              <th>{{ $store.state.workplace.sLblWpName }}</th>
            </thead>
            <tbody>
              <tr
                v-for="(tDat, idx) in vTblListData"
                :key="tDat.wp_id"
                :id="'cus-wp-' + tDat.wp_id"
                @click="doStorageRowInfo(tDat.wp_id)"
              >
                <td>{{ idx + 1 }}</td>
                <td>{{ tDat.wp_name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <hr />

      <!-- 入力エリア -->
      <div
        v-if="
          vUpdateMode == 1 ||
          (vSelectedRow && (vUpdateMode == 2 || vUpdateMode == 3))
        "
        class="uk-flex uk-flex-center uk-margin"
      >
        <div class="uk-container uk-width-1-1">
          <div class="uk-text-center" uk-grid>
            <div class="uk-width-1-1" style="text-align: left">
              <h4 style="color: #696969">入力欄</h4>
            </div>
            <div class="uk-width-1-3">
              <div class="uk-margin">
                <div style="text-align: left">
                  <label class="uk-form-label uk-text-muted">{{
                    $store.state.workplace.sLblWpName
                  }}</label>
                  <span
                    style="
                      background-color: #ff0000;
                      color: #ffffff;
                      font-size: 0.5em;
                      border-radius: 20px;
                      padding: 2px;
                      box-sizing: border-box;
                    "
                    ><b>必須</b></span
                  >
                </div>
                <input
                  class="uk-input"
                  type="text"
                  v-model="vWpName"
                  :disabled="vUpdateMode == 3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="padding: 10px">
        <div style="width: 100%; text-align: right; margin-bottom: 10px">
          <button
            v-show="vUpdateMode == 1 || (vSelectedRow && vUpdateMode == 2)"
            type="button"
            class="operate-button"
            style="
              width: 10%;
              line-height: 3;
              background-color: #696969;
              border-radius: 10px;
              border: solid 1px #696969;
              color: #ffffff;
            "
            @click="doConfirmUpdate(vModalId)"
          >
            更新
          </button>
          <button
            v-show="vUpdateMode == 3 && vSelectedRow"
            type="button"
            class="operate-button"
            style="
              width: 10%;
              line-height: 3;
              background-color: #696969;
              border-radius: 10px;
              border: solid 1px #696969;
              color: #ffffff;
            "
            @click="doConfirmUpdate(vModalId)"
          >
            削除
          </button>
        </div>
      </div>

      <!-- 何かメッセージ出す用 -->
      <p>
        <b>{{ vMessage }}</b>
      </p>

      <!-- 更新時モーダル -->
      <div :id="vModalId" uk-modal>
        <div class="uk-modal-dialog">
          <button
            class="uk-modal-close-default"
            type="button"
            uk-close
          ></button>
          <div class="uk-modal-header">
            <h3 class="uk-modal-title" style="color: #696969">確認</h3>
          </div>
          <div class="uk-modal-body" uk-overflow-auto>
            <p style="color: #696969">
              {{ $store.state.common.sUpdateModalMessage }}
            </p>
            <div style="padding: 10px">
              <div style="width: 100%; text-align: center; margin-bottom: 10px">
                <table class="sc-table" border="1">
                  <tr>
                    <th class="sc-th">
                      {{ $store.state.workplace.sLblWpName }}
                    </th>
                    <td>{{ vWpName }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="uk-modal-footer uk-text-right">
            <div style="padding: 10px">
              <div
                style="
                  width: 100%;
                  text-align: right;
                  margin-bottom: 10px;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <button
                  type="button"
                  class="operate-button"
                  style="
                    width: 20%;
                    line-height: 3;
                    background-color: #ffffff;
                    border-radius: 10px;
                    border: solid 1px #696969;
                    color: #696969;
                  "
                  @click="doHideModal(vModalId)"
                >
                  キャンセル
                </button>
                <button
                  :disabled="$store.state.common.sServerProcessing"
                  type="button"
                  class="operate-button"
                  style="
                    width: 20%;
                    line-height: 3;
                    background-color: #696969;
                    border-radius: 10px;
                    border: solid 1px #696969;
                    color: #ffffff;
                  "
                  @click="doUpdateInfo"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import constant from "@/constant.js";
import UIkit from "uikit";

export default {
  data() {
    return {
      vComId: this.$store.state.common.sComId,
      vTblListData: [],
      vWpId: "",
      vWpName: "",
      vUpdateMode: constant.cPageOperationMode.nothing,
      vSelectedRow: false,
      vModalId: "modal-confirm-customer-workplace",
      vMessage: "",
      nowUpdateMode: {
        "background-color": "#696969",
        color: "#ffffff",
        "font-weight": "bold",
      },
    };
  },
  created() {
    // 画面読み込み中・・・
    this.$store.state.common.sIsLoading = true;
    // ログイン状態判定
    this.doAuthLoginInfo();
    if (!this.$store.state.common.sIsLogined) {
      this.$router.push("/login");
    }
    // 画面タイトル
    this.$store.commit("common/doSetScreenTitle", {
      vScreenTitle: "クライアント/職場情報",
    });
    // DB参照処理
    if (this.vComId) {
      this.vMessage = "";
      this.$axios
        .get(constant.cServerRoute.customer.workplace, {
          params: {
            pComId: this.vComId,
          },
        })
        .then(
          function (response) {
            console.log(response);
            // 職場情報参照処理判定
            if (
              response.data.return_result ==
              constant.cServerReturnResult.success
            ) {
              // 職場情報を保持する
              if (response.data.return_data) {
                this.vTblListData = response.data.return_data;
              }
            } else {
              this.vMessage = response.data.return_message;
            }
          }.bind(this)
        )
        .catch(function (error) {
          console.log(error);
          this.vMessage = error;
        });
    }
  },
  // 画面読み込み終わった？
  mounted() {
    this.$store.state.common.sIsLoading = false;
  },
  methods: {
    // DB更新処理
    doUpdateInfo() {
      this.vMessage = "";
      // 入力チェック
      this.$store.state.common.sAbleUpdate = true;
      this.doCheckValueBeforeUpdate();
      if (!this.$store.state.common.sAbleUpdate) {
        // モーダル閉じる
        this.doHideModal(this.vModalId);
        return;
      }
      // ボタン制御
      this.$store.commit("common/doStartServerProcess");
      // DB更新
      this.$axios
        .post(constant.cServerRoute.customer.workplace, {
          pUpdateMode: this.vUpdateMode,
          pComId: this.vComId,
          pWpId: this.vWpId,
          pWpName: this.vWpName,
        })
        .then(
          function (response) {
            console.log(response);
            // 職場情報更新処理判定
            if (
              response.data.return_result ==
              constant.cServerReturnResult.success
            ) {
              this.vTblListData = response.data.return_data;
              // 更新メッセージ表示
              this.$store.commit("common/doNotificationUpdate", {
                vMode: constant.cNotificationUpdateMode.success,
              });
              // モーダル閉じる
              this.doHideModal(this.vModalId);
              // 値クリアしておく
              this.doClear();
              // ボタン制御
              this.$store.commit("common/doEndServerProcess");
            } else {
              this.vMessage = response.data.return_message;
              // 更新メッセージ表示
              this.$store.commit("common/doNotificationUpdate", {
                vMode: constant.cNotificationUpdateMode.failure,
              });
              // ボタン制御
              this.$store.commit("common/doEndServerProcess");
            }
          }.bind(this)
        )
        .catch(function (error) {
          // 更新メッセージ表示
          this.$store.commit("common/doNotificationUpdate", {
            vMode: constant.cNotificationUpdateMode.failure,
          });
          // モーダル閉じる
          this.doHideModal(this.vModalId);
          console.log(error);
          this.vMessage = error;
          // ボタン制御
          this.$store.commit("common/doEndServerProcess");
        });
    },
    // 更新前値チェック処理
    doCheckValueBeforeUpdate() {
      this.$store.commit("common/doCheckInput", {
        vVal: this.vWpName,
        vLabel: this.$store.state.workplace.sLblWpName,
      });
    },
    // DB更新前確認処理
    doConfirmUpdate() {
      UIkit.modal("#" + this.vModalId).show();
    },
    // モーダル閉じる
    doHideModal(modalId) {
      UIkit.modal("#" + modalId).hide();
    },
    // 入力エリア表示制御
    doSwitchInputArea(mode) {
      this.doClear();
      this.vUpdateMode = mode;
      // レコード操作モード表示名設定
      this.$store.commit("common/doSetUpdateModeDisplayName", {
        vUpdateMode: this.vUpdateMode,
      });
      // 更新モーダル内メッセージ設定
      this.$store.commit("common/doSetUpdateModalMessage", {
        vUpdateMode: this.vUpdateMode,
      });
    },
    // 選択行情報保持
    doStorageRowInfo(key1) {
      if (
        this.vUpdateMode == constant.cPageOperationMode.change ||
        this.vUpdateMode == constant.cPageOperationMode.delete
      ) {
        // 行色制御
        this.doChangeRowColor(constant.cChangeRowColor.nothing);
        var i = 0;
        for (i = 0; i < this.vTblListData.length; i++) {
          if (this.vTblListData[i].wp_id == key1) {
            this.vWpId = key1;
            this.vWpName = this.vTblListData[i].wp_name;
            break;
          }
        }
        // 選択行に色をつける
        this.doChangeRowColor(constant.cChangeRowColor.coloring);
        // 行選択状態
        this.vSelectedRow = true;
      }
    },
    // 値をクリア
    doClear() {
      this.vUpdateMode = constant.cPageOperationMode.nothing;
      this.vSelectedRow = false;
      // 行色制御
      this.doChangeRowColor(constant.cChangeRowColor.nothing);
      // 選択行情報クリア
      this.vWpId = "";
      this.vWpName = "";
    },
    // 一覧行の色変更
    // mode:0（行の色を消す）、mode:1（選択行に色をつける）
    doChangeRowColor(mode) {
      // var i = 0;
      var setColor = "";
      if (mode == constant.cChangeRowColor.coloring) {
        setColor = "#e0ffff";
      }
      var tmpInput1 = document.getElementById("cus-wp-" + this.vWpId);
      // var tmpInput2 = document.getElementById('cus-wp-' + this.vWpId + '-2');
      if (tmpInput1) {
        tmpInput1.style.backgroundColor = setColor;
      }
      // if (tmpInput2) {tmpInput2.style.backgroundColor = setColor}
    },
    // 戻る処理
    doBackToHome() {
      this.$router.push("/customer-home");
    },
    // ID,パスワード照合
    doAuthLoginInfo() {
      if (sessionStorage.getItem("storageId")) {
        let createHash = require("sha256-uint8array").createHash;
        let tmp = createHash()
          .update(sessionStorage.getItem("storagePw"))
          .digest("hex");
        this.$axios
          .post(constant.cServerRoute.common.login, {
            pLoginId: sessionStorage.getItem("storageId"),
            pLoginPw: tmp,
          })
          .then(
            function (response) {
              console.log(response);
              if (
                response.data.return_result !=
                constant.cServerReturnResult.success
              ) {
                this.$store.state.common.sIsLogined = false;
              }
            }.bind(this)
          )
          .catch(function (error) {
            console.log(error);
            this.$store.state.common.sIsLogined = false;
          });
      }
    },
  },
  // ページを離れる
  beforeRouteLeave() {
    UIkit.modal("#" + this.vModalId).$destroy(true);
  },
};
</script>
